import axios from 'axios';
import { DoublesEntry } from '../models/doubles-entry';
import { Player } from '../models/player';
import { SinglesEntry } from '../models/singles-entry';
import { Tournament } from '../models/tournament';
import { TournamentResult } from '../models/tournament-result';
import { TournamentRound } from '../models/tournament-round';
import { MembershipPaymentReports } from '../models/membership-payment-reports';

export default {
    namespaced: true,

    state: {
        sysCodes: [],
        loadingSysCodes: false,
        loadingError: null,
        savingSysCode: false,
        savingError: null,
        deletingSysCode: false,
        deletingError: null,
        options: {
            queryString: null,
            sortBy: 'tableName',
            sortDir: 'ascending',
            page: 0,
            pageSize: 20,
        },
    },

    getters: {
        sysCodes: (state) => state.sysCodes,
        loadingSysCodes: (state) => state.loadingSysCodes,
        loadingError: (state) => state.loadingError,
        savingSysCode: (state) => state.savingSysCode,
        savingError: (state) => state.savingError,
        deletingSysCode: (state) => state.deletingSysCode,
        deletingError: (state) => state.deletingError,
        options: (state) => state.options,
    },

    mutations: {
        setSysCodes(state, data) {
            state.sysCodes = data;
        },

        startLoadingSysCodes(state) {
            state.loadingSysCodes = true;
        },

        stopLoadingSysCodes(state) {
            state.loadingSysCodes = false;
        },

        setLoadingError(state, error) {
            state.loadingError = error;
        },

        clearLoadingError(state) {
            state.loadingError = null;
        },

        startSavingSysCode(state) {
            state.savingSysCode = true;
        },

        stopSavingSysCode(state) {
            state.savingSysCode = false;
        },

        setSavingError(state, error) {
            state.savingError = error;
        },

        clearSavingError(state) {
            state.savingError = null;
        },

        startDeletingSysCode(state) {
            state.deletingSysCode = true;
        },

        stopDeletingSysCode(state) {
            state.deletingSysCode = false;
        },

        setDeletingError(state, error) {
            state.deletingError = error;
        },

        clearDeletingError(state) {
            state.deletingError = null;
        },
    },

    actions: {
        async applySysCodes() {
            // this method is only used to apply sys codes to lookup fields in appropriate schemas!
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/SysCode`, {
                    params: {
                        sortBy: 'tableName',
                        sortDir: 1,
                    },
                });
                refreshLookupFields(result.data.data);
                return Promise.resolve(result.data);
            } catch (error) {
                return Promise.reject(error);
            }
        },

        async findSysCodes(context, options = {}) {
            context.commit('startLoadingSysCodes');
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/SysCode`, {
                    params: {
                        queryString: options.queryString || '',
                        sortBy: options.sortBy || 'tableName',
                        sortDir: options.sortDir === 'descending' ? -1 : 1,
                        pageSize: options.pageSize || 50,
                        page: options.page,
                    },
                });
                context.commit('stopLoadingSysCodes');
                context.commit('setSysCodes', result.data);
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('stopLoadingSysCodes');
                context.commit('setLoadingError', error);
                return Promise.reject(error);
            }
        },

        async saveSysCode(context, sysCode) {
            context.commit('startSavingSysCode');
            try {
                const result = await axios.post(`${process.env.VUE_APP_API_URL}/SysCode`, sysCode.data);
                context.commit('stopSavingSysCode');
                // context.commit('setSysCode', new SysCode(flatten(result.data)));
                await context.dispatch('applySysCodes');
                return Promise.resolve(sysCode.data);
            } catch (error) {
                context.commit('stopSavingSysCode');
                context.commit('setSavingError', error);
                return Promise.reject(error);
            }
        },

        async deleteSysCode(context, data) {
            context.commit('startDeletingSysCode');
            try {
                const sysCode = await axios.post(`${process.env.VUE_APP_API_URL}/SysCode/Delete`, data.data);
                context.commit('stopDeletingSysCode');
                // context.commit('setSysCodes', new SysCode(flatten(sysCode.data)));
                await context.dispatch('applySysCodes');
                return Promise.resolve(sysCode.data);
            } catch (error) {
                context.commit('stopDeletingSysCode');
                context.commit('setDeletingError', error);
                return Promise.reject(error);
            }
        },

        async exportCsv(context, options = {}) {
            context.commit('startLoadingSysCodes');
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/SysCode/export/csv`, {
                    params: {
                        queryString: options.queryString || '',
                        sortBy: options.sortBy || 'LastName',
                        sortDir: options.sortDir === 'descending' ? -1 : 1,
                    },
                });
                context.commit('stopLoadingSysCodes');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('stopLoadingSysCodes');
                return Promise.reject(error);
            }
        },
    },
};

function refreshLookupFields(sysCodes) {
    const relevantSchemas = [DoublesEntry.schema, Player.schema, SinglesEntry.schema, Tournament.schema, TournamentResult.schema, TournamentRound.schema, MembershipPaymentReports.schema];

    for (const schema of relevantSchemas) {
        for (const key of Object.keys(schema.fields)) {
            if (schema.fields[key].sysCode) {
                const sysCodeData = sysCodes.filter((x) => x.tableName === schema.fields[key].sysCode);
                if (sysCodeData && sysCodeData.length) {
                    schema.fields[key].lookup = sysCodeData.map((x) => ({
                        id: x.code,
                        displayName: x.ref + (x.description && x.description.trim().length > 0 ? '  (' + x.description + ')' : ''),
                    }));
                }
            }
        }
    }
}
