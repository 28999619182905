<template>
    <div class="flex-row">
        <el-select
                :clearable="clearable"
                :disabled="disabled"
                :loading="loading"
                :value="value"
                :remote-method="remoteMethod"
                filterable
                placeholder="Search by tournament name or ID"
                remote
                reserve-keyword
                @change="onChange"
                @clear="onClear"
                @focus="onFocus"
        >
            <el-option v-for="(item, i) in options" :key="i" :label="item.season + ' - ' + item.name" :value="item.season + '/' + item.tournamentId"></el-option>
        </el-select>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    props: {
        value: {
            type: String,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        clearable: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            edition: null,
            options: [],
            loading: false,
        };
    },
    mounted() {
        if (this.value) {
            this.remoteMethod(this.value);
        }
    },
    methods: {
        onChange(item) {
            if (item !== undefined && item !== null) {
                const [season, tournamentId] = item.split('/');
                let t = this.options.find((t) => t.season === Number(season) && t.tournamentId === Number(tournamentId));
                this.$emit('tournament-selected', t);
                this.$emit('input', item);
                this.$emit('change', item);
            }
        },
        remoteMethod(queryString) {
            if (typeof queryString === 'string' && queryString.length >= 3) {
                this.loading = true;
                axios
                    .get(`${process.env.VUE_APP_API_URL}/Edition?queryString=${queryString || ''}`)
                    .then((result) => {
                        this.options = result.data.data;
                        this.loading = false;
                    })
                    .catch((error) => {
                        this.loading = false;
                        this.$notify({
                            title: 'Tournaments Fetch Error',
                            message: error.response.data.Message,
                            type: 'error',
                            duration: 5000,
                        });
                    });
            } else {
                this.options = [];
            }
        },
        onClear() {
            this.$emit('clear');
        },
        onFocus() {
            this.options = [];
        },
    },
};
</script>

<style></style>
