import * as _ from "lodash";

import axios from "axios";

export default {
    namespaced: true,
    state: {
        membershipPaymentData: {},
        loadingMembershipPaymentData: false,
        loadingError: null,
        savingError: null,
        options: {            
            queryString: null,
            sortBy: '',
            sortDir: 'descending',
            page: 0,
            pageSize: 20,
        },
    },
    getters: {
        membershipPaymentData: (state) => state.membershipPaymentData,
        loadingMembershipPaymentData: (state) => state.loadingMembershipPaymentData,
        loadingError: (state) => state.loadingError,
        options: (state) => state.options,    
        savingMembershipPayment: (state) => state.savingMembershipPayment,  
        savingError: (state) => state.savingError,  
    },
    mutations: {   
        startSavingMembershipPayment(state) { state.savingMembershipPayment = true; },  
        stopSavingMembershipPayment(state) { state.savingMembershipPayment = false; },  
        setSavingError(state, error) { state.savingError = error; },
        clearSavingError(state) { state.savingError = null; },
        setMembershipPaymentData(state, data) {
            state.membershipPaymentData = data;
        },

        startLoadingMembershipPaymentData(state) {
            state.loadingMembershipPaymentData = true;
        },

        stopLoadingMembershipPaymentData(state) {
            state.loadingMembershipPaymentData = false;
        },

        setLoadingError(state, error) {
            state.loadingError = error;
        },

        clearLoadingError(state) {
            state.loadingError = null;
        },
    },
    actions: {
        async searchMembershipPaymentData(context, options = {}) {
            context.commit('startLoadingMembershipPaymentData');
            context.commit('clearLoadingError');
            try {                
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/MembershipPaymentReports/Search`, {
                    params: {
                        queryString: options.queryString || '',
                        pageSize: options.pageSize || 20,
                        sortBy: options.sortBy || '',
                        sortDir: options.sortDir === 'descending' ? -1 : 1,
                        page: options.page,
                        role: options.role,                        
                    },
                });
                context.commit('setMembershipPaymentData', result.data);
                context.commit('stopLoadingMembershipPaymentData');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setLoadingError', error);
                context.commit('stopLoadingMembershipPaymentData');
                return Promise.reject(error);
            }
        },  
        async updateMembershipPaymentData(context, data = {}) {                 
            context.commit('startSavingMembershipPayment');
            context.commit('clearSavingError');   
            try {                
                const result = await axios.put(`${process.env.VUE_APP_API_URL}/MembershipPaymentReports/Update`, data.data);                
                context.commit('stopSavingMembershipPayment');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setSavingError', error);
                context.commit('stopSavingMembershipPayment');
                return Promise.reject(error);
            }
        },          
    },
};