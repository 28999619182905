import axios from 'axios';

function extractEntries(section, mode) {
    if (section === undefined) return [];

    let entries = [];
    for (let i = 0; i < section.length; i++) {
        if (section[i].within === 'Top1Alternate') section[i].within = 1;
        else if (section[i].within === 'Top2Alternates') section[i].within = 2;
        else if (section[i].within === 'Top3Alternates') section[i].within = 3;
        else if (section[i].within === 'Top4Alternates') section[i].within = 4;
        else section[i].within = '–';

        if (section[i].preference === 'First') section[i].preference = 1;
        else if (section[i].preference === 'Second') section[i].preference = 2;
        else if (section[i].preference === 'Third') section[i].preference = 3;
        else section[i].preference = '–';

        if (mode === 'S') {            
            entries.push({
                position: i + 1,
                playerName: [section[i].player.firstName.trim(), section[i].player.lastName.trim()].join(' '),
                nationality: supressBlrRusNationality(section[i].player.nationality),
                entryRank: section[i].rank + (section[i].withSpecialRank ? ' SR' : ''),
                preference: section[i].preference,
                within: section[i].within,
                comment: section[i].comment,
                currentRank: section[i].player.currentRank !== null ? section[i].player.currentRank.singles : null,
                status: section[i].status,
                membership: section[i].player.membership,
                isConfirmedPlayerForInternationalTournaments: section[i].isConfirmedPlayerForInternationalTournaments,
                orgStatus: section[i].orgStatus,
            });
        } else {            
            // first column should always show the shorter name
            if (section[i].player.fullName > section[i].partner.fullName) {
                entries.push({
                    position: i + 1,
                    combinedRank: section[i].rank,
                    playerName: [section[i].partner.firstName.trim(), section[i].partner.lastName.trim()].join(' '),
                    playerNationality:  supressBlrRusNationality(section[i].partner.nationality),
                    playerSinglesRank: section[i].partnerUsesSpecialRank ? section[i].partnerSpecialRank + ' SR' : section[i].partnerSinglesRank,
                    playerRank: section[i].partnerUsesSpecialRank ? section[i].partnerSpecialRank + ' SR' : section[i].partnerDoublesRank,
                    partnerName: [section[i].player.firstName.trim(), section[i].player.lastName.trim()].join(' '),
                    partnerNationality:  supressBlrRusNationality(section[i].player.nationality),
                    partnerSinglesRank: section[i].playerUsesSpecialRank ? section[i].playerSpecialRank + ' SR' : section[i].playerSinglesRank,
                    partnerRank: section[i].playerUsesSpecialRank ? section[i].playerSpecialRank + ' SR' : section[i].playerDoublesRank,
                    currentCombinedRank: section[i].player.currentRank.doubles + section[i].partner.currentRank.doubles,
                    status: section[i].status,
                    playerMembership: section[i].partner.membership,
                    partnerMembership: section[i].player.membership,
                });
            } else {                
                entries.push({
                    position: i + 1,
                    combinedRank: section[i].rank,
                    playerName: [section[i].player.firstName.trim(), section[i].player.lastName.trim()].join(' '),
                    playerNationality:  supressBlrRusNationality(section[i].player.nationality),
                    playerSinglesRank: section[i].playerUsesSpecialRank ? section[i].playerSpecialRank + ' SR' : section[i].playerSinglesRank,
                    playerRank: section[i].playerUsesSpecialRank ? section[i].playerSpecialRank + ' SR' : section[i].playerDoublesRank,
                    partnerName: [section[i].partner.firstName.trim(), section[i].partner.lastName.trim()].join(' '),
                    partnerNationality:  supressBlrRusNationality(section[i].partner.nationality),
                    partnerSinglesRank: section[i].partnerUsesSpecialRank ? section[i].partnerSpecialRank + ' SR' : section[i].partnerSinglesRank,
                    partnerRank: section[i].partnerUsesSpecialRank ? section[i].partnerSpecialRank + ' SR' : section[i].partnerDoublesRank,
                    currentCombinedRank: section[i].player.currentRank.doubles + section[i].partner.currentRank.doubles,
                    status: section[i].status,
                    playerMembership: section[i].player.membership,
                    partnerMembership: section[i].partner.membership,
                });
            }
        }
    }

    function supressBlrRusNationality(string) {
        return string === 'RUS' || string === 'BLR' ? '' : string;
     }

    console.debug(entries);
    return entries;
}

export default {
    namespaced: true,

    state: {
        acceptanceList: {
            singles: {
                main: {
                    draw: [],
                    alternates: [],
                    withdrawn: [],
                },
                quali: {
                    draw: [],
                    alternates: [],
                    withdrawn: [],
                },
            },
            doubles: {
                draw: [],
                alternates: [],
                withdrawn: [],
            },
        },
        loadingAcceptanceList: false,
        loadingError: null,
        editions: null,
        loadingEditions: false,
        loadingEditionsError: null,
        edition: null,
        useCurrentRanks: false,
        editionData: null,
    },

    getters: {
        acceptanceList: (state) => state.acceptanceList,
        loadingAcceptanceList: (state) => state.loadingAcceptanceList,
        loadingError: (state) => state.loadingError,
        editions: (state) => state.editions,
        loadingEditions: (state) => state.loadingEditions,
        loadingEditionsError: (state) => state.loadingEditionsError,
        edition: (state) => state.editions,
        useCurrentRanks: (state) => state.useCurrentRanks,
        editionData: (state) => state.editionData,
    },

    mutations: {
        setAcceptanceList(state, data) {
            state.acceptanceList = data;
        },

        startLoadingAcceptanceList(state) {
            state.loadingAcceptanceList = true;
        },

        stopLoadingAcceptanceList(state) {
            state.loadingAcceptanceList = false;
        },

        setLoadingError(state, error) {
            state.loadingError = error;
        },

        clearLoadingError(state) {
            state.loadingError = null;
        },

        setEditions(state, data) {
            // #7651 do not include ITF
            let editionsFiltered = [];
            data.forEach((e) => {
                if (e.type !== 'ITF') {
                    editionsFiltered.push(e);
                }
            });
            state.editions = editionsFiltered;
        },

        setEdition(state, data) {
            state.edition = data;
        },

        setUseCurrentRanks(state, data) {
            state.useCurrentRanks = data;
        },

        setEditionData(state, data) {
            state.editionData = data;
        },
    },

    actions: {
        async findByEdition(context, edition) {
            context.commit('startLoadingAcceptanceList');

            try {
                let result = {
                    singles: {
                        main: {
                            draw: [],
                            alternates: [],
                            withdrawn: [],
                        },
                        quali: {
                            draw: [],
                            alternates: [],
                            withdrawn: [],
                        },
                    },
                    doubles: {
                        draw: [],
                        alternates: [],
                        withdrawn: [],
                    },
                };

                let src = await axios.get(
                    `${process.env.VUE_APP_API_URL}/AcceptanceList/${edition}/CurrentLists/${context.state.useCurrentRanks ? 'true' : 'false'}`,
                );
                context.commit('stopLoadingAcceptanceList');
                if (src.data.main !== null) {
                    result.singles.main.draw = extractEntries(src.data.main.draw, 'S');
                    result.singles.main.alternates = extractEntries(src.data.main.alternates, 'S');
                    result.singles.main.withdrawn = extractEntries(src.data.main.withdrawn, 'S');
                }

                if (src.data.quali !== null) {
                    result.singles.quali.draw = extractEntries(src.data.quali.draw, 'S');
                    result.singles.quali.alternates = extractEntries(src.data.quali.alternates, 'S');
                    result.singles.quali.withdrawn = extractEntries(src.data.quali.withdrawn, 'S');
                }

                if (src.data.doubles !== null) {
                    result.doubles.draw = extractEntries(src.data.doubles.draw, 'D');
                    result.doubles.alternates = extractEntries(src.data.doubles.alternates, 'D');
                    result.doubles.withdrawn = extractEntries(src.data.doubles.withdrawn, 'D');
                }

                context.commit('setAcceptanceList', result);
                context.commit('setEdition', edition);
                context.commit('setEditionData', src.data.edition);
                context.state.useCurrentRanksWasChanged = false;

                return [result, src.data.edition];
            } catch (error) {
                console.debug(error);
                context.commit('stopLoadingAcceptanceList');
                context.commit('setLoadingError', error);
                return null;
            }
        },

        async findEditionsForAcceptanceList(context, date) {            
            context.commit('startLoadingAcceptanceList');
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/Edition/ForAcceptanceList/${date}`);
                context.commit('stopLoadingAcceptanceList');
                context.commit('setEditions', result.data);
                return result.data;
            } catch (error) {
                console.debug(error);
                context.commit('stopLoadingAcceptanceList');
                context.commit('setLoadingError', error);
                return null;
            }
        },
    },
};
